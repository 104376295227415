<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
              <label for="">From</label>          &nbsp;

              <Calendar
              style="width: 200%"
              v-model="from_date"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="1"
              placeholder="From Date"
            />
          &nbsp;
          <label for="">To</label>          &nbsp;
            <Calendar
              style="width: 200%"
              v-model="to_date"
              :showIcon="true"
              dateFormat="d-MM-yy"
              hourFormat="12"
              :numberOfMonths="1"
              placeholder="To Date"
            />
           
          
              &nbsp;&nbsp;&nbsp;
            <Button
              @click="get_list"
              style="width: 35%"
              icon="pi pi-search"
              class="p-button-rounded p-button-success"
            />
           
          </template>

          <template v-slot:end>
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true"
          showGridlines
        >
          <template #empty>
            <b style="text-align: center">No records found.</b>
          </template>

          <template #header>
            <div
              class="flex flex-column md:flex-row md:justify-content-between md:align-items-center"
            >
              <h5 class="m-0"><b>Network Connectivity Report 1</b></h5>
              <Button icon="pi pi-refresh" @click="get_list()" />
              
            </div>
          </template>

         
          <Column header="Sr No" style="min-width: 5rem; text-align: center">
            <template #body="{ index }">
              <span v-if="page_no == 1">{{ +index + +1 }}</span>
              <span v-else>{{ +index + 1 + limit * (page_no - 1) }}</span>
            </template>
          </Column>

          <Column
            header="Device ID"
             style="min-width: 22rem; text-align: left"
            :field="columns[0]"
          >
            <template #body="{ data }">
           {{data.devicE_ID}}
              
            </template>
          </Column>
          <Column
            header="Connectivity Count"
            style=" text-align: center;min-width: 2rem;"
            :field="columns[1]"
          >
            <template #body="{ data }">
              {{ data.connectivitycount }}
            </template>
          </Column>
          <Column
            header="Record Count"
            style=" text-align: center;min-width: 2rem;"
            :field="columns[2]"
          >
            <template #body="{ data }">
              {{ data.recordcount }}
            </template>
          </Column>
          <Column header="CPN Connectivity %" :field="columns[3]" style=" text-align: center;min-width: 2rem;">
            <template #body="{ data }">
              {{ data.connectivityperc }}
            </template>
          </Column>
          

        
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      from_date: new Date(),
      to_date: new Date(),
      page_no: 1,
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,

      products: null,

      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,

      limit: 10,
      columns: [],

      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        // await this.get_count();
        // this.get_list();
      }
    },
  },
  methods: {
    //a simple date formatting function

    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
     // await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {

     
    },
   
    get_list: function () {

      if((this.from_date &&
        this.to_date=='')||(this.from_date=='' &&
        this.to_date))
        {
          this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Select Date",
            life: 3000,
          });
          return false;
        }

      var f_date ="";
      var t_date="";
      if (
        this.from_date &&
        this.to_date
      ) {
        var from = new Date(this.from_date);
        var to = new Date(this.to_date);
         f_date =
          from.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from.toLocaleDateString("en-US", { day: "2-digit" });

         t_date =
          to.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          to.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          to.toLocaleDateString("en-US", { day: "2-digit" });
        }
        else{
           f_date="";
           t_date="";
        }


      var data = {
        from_date: f_date,
          to_date: t_date,
       
      };
      this.loading = true;
      var promise = apis.connectivityreport1(data);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        this.totalRecords = response.data.length;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    
    },

    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
